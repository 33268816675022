<template>
  <div class="page">
    <header class="header">加我微信</header>
    <div class="main">

    </div>
  </div>
</template>

<style lang='scss' scord>
</style>

<script>
export default {
  data() {
    return {};
  },

  created() {
    this.shop_id = this.$route.params.shop_id
  },

  methods: {},
};
</script>